<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <div class="headline">Editar perfil</div>
      </v-col>
    </v-row>

   <PersonaUpdate :persona="cliente.persona"></PersonaUpdate>
 
  </v-container>
</template>

<script>
import ClienteForm from "@/components/cliente/ClienteForm";
import PersonaAPI from "@/api/persona";

import { fetchCliente } from "@/mixins/cliente/fetchCliente";

import { mapActions } from "vuex";
import PersonaUpdate from '@/views/usuarios/PersonaUpdate.vue';
export default {
  name: "cliente-update",
  mixins: [fetchCliente],
  components: {
    ClienteForm,
    PersonaUpdate,
  },

  data() {
    return {
      provincias: [],
    };
  },

  methods: {
    ...mapActions("cliente", ["actualizar_cliente", "get_cliente"]),

    actualizarCliente(cliente) {
      this.actualizar_cliente(cliente).then(() => {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: "Cliente actualizado",
          icon: "mdi-bell-ring",
          timeout: 3000,
        });
        this.$router.push({ path: `/clientes/${cliente.id}` });
      });
    },
    async actualizarPersona(persona) {
      try {
        const response = await PersonaAPI.update(persona.id, persona);
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: "Actualizado",
          icon: "mdi-information",
          timeout: 3000,
        });
      } catch (error) {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "info",
          text: "Error de red al actualiza",
          icon: "mdi-information",
          timeout: 3000,
        });
        // Maneja errores de red u otros errores inesperados
        console.error("Error de red al actualizar la persona:", error);
      }
    },
    async fetchProvincias() {
      try {
        const provincias = await this.axios.get("/soporte/provincias");

        this.provincias = provincias.data;
      } catch (error) {}
    },
  },
  mounted() {
    this.fetchProvincias();
  },
};
</script>

<style lang="scss" scoped></style>
