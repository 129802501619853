<template>
  <v-container fluid>
    <!-- <usuario-register-form
      @registrarUsuario="registrarUsuario"
      :user="user"
    ></usuario-register-form> -->

    <v-row align="center" justify="center">
      <v-col xs="8" sm="8" md="8">
        <ValidationObserver ref="formPersona">
          <v-card slot-scope="{ invalid, validated }">
            <v-card-title v-if="step == 1">
              <v-spacer></v-spacer>
              <v-btn @click="nextStep" :disabled="invalid">Siguiente</v-btn>
            </v-card-title>
            <v-card-title v-else>
              <v-btn icon @click="step--"><v-icon>mdi-arrow-left</v-icon></v-btn>
            </v-card-title>
            <v-card-text v-if="step == 1">
              <v-col>
                <div class="headline"><b>Registrar usuario del sistema</b></div>
              </v-col>

              <v-col>
                <VTextFieldWithValidation
                  outlined
                  autocapitalize="sentences"
                  label="Nombres"
                  v-model="persona.nombre"
                  rules="required|max:30"
                  name="nombre"
                  type="text"
                />
              </v-col>
              <v-col>
                <VTextFieldWithValidation
                  outlined
                  autocapitalize="sentences"
                  label="Apellidos"
                  v-model="persona.apellido"
                  rules="required|max:30"
                  name="apellido"
                  type="text"
                />
              </v-col>
              <v-col>
                <VTextFieldWithValidation
                  outlined
                  rules="required|max:30"
                  label="Documento"
                  v-model="persona.documento"
                  name="documento"
                  type="text"
                />
              </v-col>

              <!-- <v-col>
                <VTextFieldWithValidation
                  outlined
                  v-model="persona.direccion"
                  label="Dirección"
                  name="Dirección"
                  rules="required|max:25"
                  :counter="25"
                />
              </v-col> -->
              <v-col>
                <VTextFieldWithValidation
                  outlined
                  v-model="persona.telefono"
                  label="Teléfono"
                  name="telefono"
                  rules="required|numeric|min:7|max:30"
                  :counter="30"
                />
              </v-col>

              <!-- <v-col>
                <VSelectWithValidation
                  outlined
                  v-model="id_provincia"
                  :items="provincias"
                  value="nombre"
                  item-text="nombre"
                  item-value="id"
                  v-validate="'required'"
                  label="Selecciona una Provincia"
                  name="Provincia"
                  @change="getLocalidades"
                />
              </v-col>
              <v-col>
                <VSelectWithValidation
                  v-model="persona.localidad.id"
                  no-data-text="Sin localidades"
                  value="nombre"
                  outlined
                  :items="localidades"
                  item-text="nombre"
                  item-value="id"
                  v-validate="'required'"
                  label="Selecciona una Localidad"
                  name="Localidad"
                />
              </v-col> -->
            </v-card-text>
            <v-card-text v-else>
              <usuario-register-form
                ref="form2"
                @registrarUsuario="registrarPersona"
                :user="user"
              ></usuario-register-form>
            </v-card-text>
            <v-card-actions v-if="step == 2">
              <v-btn
                rounded
                block
                large
                color="green"
                :loading="loading"
                @click="validateForm()"
                :disabled="invalid"
                >Registrar</v-btn
              >
            </v-card-actions>
          </v-card>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UsuarioRegisterForm from "@/components/usuarios/UsuarioRegisterForm";
import VTextFieldWithValidation from "@/components/inputs/VTextFieldWithValidation";
import VSelectWithValidation from "@/components/inputs/VSelectWithValidation";
import { ValidationObserver } from "vee-validate";

import { mapState, mapActions } from "vuex";
export default {
  name: "PersonaRegisterForm",
  $_veeValidate: {
    validator: "new",
  },
  components: {
    VTextFieldWithValidation,
    VSelectWithValidation,
    ValidationObserver,
    UsuarioRegisterForm,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    persona: {
      type: Object,
      required: true,
    },
    provincias: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      disabled: false,
      passwordConfirm: null,
      localidades: [],
      loading: false,
      id_provincia: null,
      step: 1,
    };
  },
  computed: {},
  methods: {
    async nextStep() {
      const result = await this.$refs.formPersona.validate();
      if (result) {
        this.step++;
      } else {
        this.$validator.validate();
      }
    },
    registrarPersona() {},
    validateForm() {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          alert("Registrando");
        } else {
          this.$validator.validate();
        }
      });

      // const result = await this.$refs.formProveedor.validate();
      // if (result) {
      //   // this.$emit("processProveedor", this.proveedor);
      //   alert("Registrando");
      //   this.loading = true;
      //   // call form submission logic
      // } else {
      //   this.$validator.validate();
      // }
    },
    async getLocalidades(id_prov) {
      try {
        const localidades = await this.axios.get(
          `/soporte/provincias/${id_prov}/localidades`
        );

        this.localidades = localidades.data;
      } catch (error) {}
    },
    confirmationPassword(value) {
      if (value !== this.user.password) {
        this.disabled = true;
        return "Las contraseñas no coinciden";
      } else {
        this.disabled = false;
        return true;
      }
    },
    ...mapActions("auth", ["signIn"]),
    async submit() {
      this.$emit("registrarUsuario", this.user);
      // call form submission logic
    },
  },
};
</script>

<style lang="scss" scoped></style>
