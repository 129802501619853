<template>
  <v-form>
    <v-text-field
      autocapitalize="sentences"
      label="Nombre de usuario"
      v-model="user.username"
      v-validate="'required|min:1|max:25'"
      :error-messages="errors.collect('nombreUsuario')"
      data-vv-name="nombreUsuario"
      name="nombre"
      prepend-icon="mdi-account"
      outlined
    />

    <v-text-field
      v-validate="'required|min:5|max:25'"
      :error-messages="errors.collect('contraseña')"
      data-vv-name="contraseña"
      label="Contraseña"
      v-model="user.password"
      name="contraseña"
      type="password"
      prepend-icon="mdi-lock"
      outlined

    />

    <v-text-field
      v-validate="'required|min:5|max:25'"
      :error-messages="errors.collect('ConfirmarContraseña')"
      data-vv-name="ConfirmarContraseña"
      :rules="[confirmationPassword]"
      prepend-icon="mdi-lock-question"
      v-model="passwordConfirm"
      name="password_confirmation"
      type="password"
      label="Confirmar contraseña"
      outlined

    />
    <v-btn block :disabled="errors.any()" @click="validateForm" color="success">
      Registrar</v-btn
    >
  </v-form>
</template>

<script>
import { mapState, mapActions } from "vuex";
import VTextFieldWithValidation from "@/components/inputs/VTextFieldWithValidation";
import { ValidationObserver } from "vee-validate";

export default {
  name: "UsuarioRegisterForm",
  inject: {
    $validator: "$validator",
  },
  components: {
    VTextFieldWithValidation,
    ValidationObserver,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      disabled: false,
      passwordConfirm: null,
    };
  },
  computed: {},
  methods: {
    confirmationPassword(value) {
      if (value !== this.user.password) {
        this.disabled = true;
        return "Las contraseñas no coinciden";
      } else {
        this.disabled = false;
        return true;
      }
    },
    ...mapActions("auth", ["signIn"]),
    validateForm() {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
      this.$emit("process-register", this.user);

        } else {
          this.$validator.validate();
        }
      });
    },
    
  },
};
</script>

<style lang="scss" scoped></style>
