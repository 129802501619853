<template>
  <div>
    <!-- MOBILE -->
    <v-card v-if="$vuetify.breakpoint.smAndDown">
      <v-container>
        <v-list>
          <v-list-item>
            <v-list-item-avatar v-if="routeBack">
              <v-btn icon @click="handleRouteBack">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </v-list-item-avatar>
            <v-list-item-content>
              <!-- <v-list-item-subtitle>
                <slot name="navigation-subtitle"></slot>
              </v-list-item-subtitle> -->
              <v-list-item-title>
                <h1 class="text-wrap" style="font-size: 35px">
                  <slot name="navigation-title">TITLE</slot>
                </h1>
              </v-list-item-title>
              <v-list-item-subtitle>
                <slot name="navigation-subtitle"></slot>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <slot name="navigation-content"></slot>
        </v-list>
      </v-container>
    </v-card>
    <!-- DESKTOP -->
    <v-navigation-drawer
      v-else
      color="greyen"
      class="scroll-bar navigation"
      clipped
      fixed
      permanent
      width="360px"
    >
      <template v-slot:prepend>
        <slot name="breadcrumbs"></slot>
        <v-list>
          <v-list-item>
            <v-list-item-avatar v-if="routeBack">
              <v-btn icon @click="handleRouteBack">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-subtitle>
                <slot name="navigation-subtitle"></slot>
              </v-list-item-subtitle>
              <v-list-item-title>
                <h1 class="text-wrap" style="font-size: 25px">
                  <slot name="navigation-title">TITLE</slot>
                </h1>
              </v-list-item-title>
              <v-list-item-subtitle>
                <slot name="navigation-subtitle-after"></slot>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <slot name="navigation-content"></slot>
        </v-list>
        <v-divider></v-divider>
      </template>

      <slot name="navigation-before-links"> </slot>
      <span v-if="showLinksMenu">
        <v-list nav v-if="linksMenu.length > 0">
          <v-list-item-group mandatory color="blue lighten-3">
            <v-list-item
              v-for="(link, i) in linksMenu"
              :key="i"
              link
              exact
              :to="{ name: link.route_name }"
              :style="`background-color:${link.color}`"
            >
              <v-list-item-icon>
                <v-icon v-text="link.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="link.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-container v-else>
          <v-row v-for="i in 6" :key="i">
            <v-col cols="12">
              <v-skeleton-loader
                max-height="350"
                type="list-item"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-container>
      </span>
      <slot name="navigation-after-links"> </slot>

      <template v-slot:append>
        <slot name="navigation-append"><v-divider></v-divider></slot>
      </template>
    </v-navigation-drawer>

    <v-main class="custom-main">
      <v-container>
        <v-row justify="center">
          <v-col cols="12">
            <slot name="vista-previa"></slot>
            <v-row>
              <v-col>
                <v-list style="background-color: transparent" flat two-line>
                  <v-list-item style="padding-left: 0px">
                    <v-list-item-content>
                      <v-list-item-title
                        ><div class="title">
                          <slot name="vista-previa-title"></slot></div
                      ></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <slot name="vista-previa-action"></slot>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <slot name="vista-previa-list"></slot>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "LayoutBasic",
  props: {
    expandOnHover: {
      type: Boolean,
      required: false,
      default: false,
    },
    width: {
      required: false,
      default: 330,
    },
    routeBack: {
      type: String | Object | Number,
      required: false,
    },
    linksMenu: {
      type: Array,
      required: false,
      default: () => [],
    },
    showLinksMenu: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  methods: {
    handleRouteBack() {

      if (typeof this.routeBack === "object" && this.routeBack !== null) {
        const routeName = this.routeBack.name;
        alert(routeName);
        this.$router.push({ name: routeName });
        // Si routeBack es un objeto, utiliza el nombre de la ruta
      } else if (typeof this.routeBack === "string") {
        // Si routeBack es una cadena, utiliza la ruta directamente
        this.$router.push(this.routeBack);
      } else if (typeof this.routeBack === "number") {
        // Si routeBack es una number
        this.$router.go(-1);
      }
      // Puedes agregar más lógica según tus necesidades
    },
  },
};
</script>

<style scoped>
.custom-main {
  padding: 0px 0px 0px 360px !important;
}

/* Estilos para pantallas pequeñas y tabletas */
@media (max-width: 960px) {
  .custom-main {
    padding: 0px !important; /* Ajusta el padding a 0 en todas las direcciones */
  }
}
.navigation {
  top: 64px !important;
  height: 100% !important;
  max-height: calc(100% - 64px);
}
.scroll-bar {
  left: auto;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.scroll-bar:hover ::-webkit-scrollbar {
  width: 8px !important;
  padding: 0px;
}
.scroll-bar ::-webkit-scrollbar {
  width: 0px;
  height: 18px;
}

.scroll-bar ::-webkit-scrollbar-track {
  background: transparent;
  padding: 0px;
}
.scroll-bar ::-webkit-scrollbar-thumb {
  background: #888;
}
</style>
