import ClienteApi from "@/api/clientes/clientes.js";


export var fetchCliente = {
  data() {
    return {
      cliente: null
    }
  },
  methods: {
    async fetchCliente(id) {
      try {
        const response = await ClienteApi.show(id)
        this.cliente = response.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    "$route.params": {
      handler: function (params) {
        console.log(params);
        if (params.cliente) {
          this.cliente = { ...params.cliente };
        } else {
          this.fetchCliente(params.id_cliente);
        }
      },
      deep: true,
      immediate: true,
    },
  },

}