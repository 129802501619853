<template>
  <v-card flat>
    <v-container>
      <v-card-text>
        <v-text-field
          outlined
          autofocus
          v-model="persona.nombre"
          label="Nombre"
          v-validate="'required|max:25'"
          :counter="30"
          :error-messages="errors.collect('nombre')"
          data-vv-name="nombre"
          required
        ></v-text-field>
        <v-text-field
          outlined
          v-model="persona.apellido"
          label="Apellido"
          v-validate="'required|max:25'"
          :counter="30"
          :error-messages="errors.collect('apellido')"
          data-vv-name="apellido"
          required
        ></v-text-field>
        <v-text-field
          outlined
          v-model="persona.email"
          label="Email"
          v-validate="'required|email'"
          :error-messages="errors.collect('email')"
          data-vv-name="email"
          required
        ></v-text-field>
        <v-text-field
          outlined
          v-model="persona.telefono"
          label="Telefono"
          v-validate="'required|numeric|min:7|max:30'"
          :error-messages="errors.collect('telefono')"
          data-vv-name="telefono"
          required
        ></v-text-field>
        <v-row>
          <v-col cols="4">

            <TipoDocumentoVSelect :entity="persona">  </TipoDocumentoVSelect>
          </v-col>
          <v-col>

            <v-text-field
              outlined
              v-model="persona.documento"
              label="Documento"
              v-validate="'required|numeric|min:7|max:11'"
              :error-messages="errors.collect('documento')"
              data-vv-name="documento"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- <date-picker-date-of-birth
          @process-date-birthday="setDateOfBirth"
        ></date-picker-date-of-birth> -->
        <v-autocomplete
          outlined
          :items="provincias"
          append-icon
          v-model="id_provincia"
          hide-no-data
          clearable
          item-text="nombre"
          item-value="id"
          label="Provincia"
          @change="refrescarLocalidades"
        ></v-autocomplete>

        <v-autocomplete
          autocomplete="off"
          v-model="persona.localidad.id"
          :items="localidades"
          outlined
          :search-input.sync="searchInputLocalidad"
          append-icon
          no-data-text="Sin localidades"
          clearable
          item-text="nombre"
          item-value="id"
          label="Localidad"
        ></v-autocomplete>
      </v-card-text>
      <v-card-actions>
        <v-row justify="end" class="mr-2">
          <v-btn text color="grey" @click="$emit('close-dialog')"
            >Cancelar</v-btn
          >

          <v-btn
            color="green"
            :loading="loading"
            @click="validateForm()"
            :disabled="errors.any()"
            >{{ textButton }}</v-btn
          >
        </v-row>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { ValidationObserver } from "vee-validate";
import VTextFieldWithValidation from "@/components/inputs/VTextFieldWithValidation";
import VSelectWithValidation from "@/components/inputs/VSelectWithValidation";
import DatePickerDateOfBirth from "@/util/DatePickerDateOfBirth.vue";
import CondicionIvaVSelect from "@/components/util/CondicionIvaVSelect.vue";
import TipoDocumentoVSelect from "@/components/util/TipoDocumentoVSelect.vue";

export default {
  name: "PersonaForm",
  components: {
    ValidationObserver,
    VTextFieldWithValidation,
    VSelectWithValidation,
    DatePickerDateOfBirth,
    CondicionIvaVSelect,
    TipoDocumentoVSelect
  },
  $_veeValidate: {
    validator: "new",
  },
  data() {
    return {
      localidades: [],
      localidadesCliente: [],
      id_provincia: null,
      loading: false,
      searchInput: "",
      searchInputLocalidad: "",

      barrios: [],
    };
  },

  props: {
    persona: {
      type: Object,
      required: true,
    },
    provincias: {
      type: Array,
      required: true,
    },
    textButton: {
      type: String,
      required: false,
      default: "Registrar",
    },

    isUpdate: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    setDateOfBirth(date) {
      this.persona.fechaNacimiento = date;
    },
    refrescarLocalidades(id_prov) {
      this.fetchLocalidades(id_prov);
      this.persona.localidad.id = null;
    },
    async fetchLocalidades(id_prov) {
      try {
        const localidades = await this.axios.get(
          `/soporte/provincias/${id_prov}/localidades`
        );

        this.localidades = localidades.data;
        this.searchInputLocalidad = "";
      } catch (error) {}
    },

    validateForm() {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          this.$emit("process-persona", this.persona);
        } else {
          this.$validator.validate();
        }
      });
    },
    fetchBarrios(id_loc) {
      this.axios.get(`/soporte/barrios/${id_loc}`).then((data) => {
        this.barrios = data.data;
      });
    },
  },
  watch: {
    id_provincia(val) {
      console.log(val);
      this.fetchLocalidades(val);
    },
  },

  created() {
    if (this.isUpdate) {
      if (this.persona.localidad) {
        this.id_provincia = this.persona.localidad.provincia.id;
        let id = this.persona.localidad.provincia.id;
        this.fetchLocalidades(id);
      }else{
        this.persona.localidad = {id: null}
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>
