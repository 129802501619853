<template>
  <v-container>
    <PersonaForm
      @process-persona="processPersona"
      :user="user"
      :persona="persona"
      :provincias="provincias"
      :isUpdate="false"
    ></PersonaForm>
  </v-container>
</template>

<script>
import PersonaForm from "@/components/cliente/persona/PersonaForm.vue"
import PersonaRegisterForm from "@/components/usuarios/PersonaRegisterForm";

import { mapActions } from "vuex";

export default {
  name: "PersonaRegister",
  components: {
    PersonaForm,
  },
  data() {
    return {
      user: {
        nombre: null,
        apellido: null,
        username: null,
        email: null,
        password: null,
      },
      persona: {
        nombre: "",
        apellido: "",
        documento: '',
        telefono: '',
        email: '',
        direccion: null,
        localidad: { id: null },
        tipo_documento: null,

      },
      provincias: [],
    };
  },
  methods: {
    ...mapActions("auth", ["registrar_usuario"]),

    processPersona(persona) {
      this.$emit('process-persona',persona);
    },
    async fetchProvincias() {
      try {
        const provincias = await this.axios.get("/soporte/provincias");

        this.provincias = provincias.data;
      } catch (error) {}
    },
  },
  created () {
    this.fetchProvincias();
  },
};
</script>

<style lang="scss" scoped></style>
