<template>
    <div>
        <v-select v-model="entity.tipo_documento" :items="tiposDocumento" value="description" outlined item-text="description"
            item-value="id" label="Tipo de documento" name="condición iva" v-validate="'required'"
            :error-messages="errors.collect('tipoDocumento')" data-vv-name="tipoDocumento"></v-select>
    </div>
</template>

<script>
export default {
    name: 'TipoDocumentoVSelect',
    $_veeValidate: {
    validator: "new",
  },

    props: {
        entity: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            tiposDocumento: null,

        }
    },
    methods: {
        async fetchTiposDocumento() {
            try {
                const response = await this.axios.get(`/clientes/tipodocumento`);

                this.tiposDocumento = response.data;
            } catch (error) { }
        },
    },
    created() {
        this.fetchTiposDocumento();
    },
}
</script>

<style lang="scss" scoped></style>