<template>
  <v-container>

    <v-row justify="center">
      <v-col cols="12">
        <PersonaForm
          v-if="persona"
          textButton="Guardar"
          @close-dialog="$emit('close-dialog')"
          :persona="persona"
          :provincias="provincias"
          :isUpdate="true"
          @process-persona="actualizarPersona"
        ></PersonaForm>
      </v-col>
    </v-row>
  
  </v-container>
</template>

<script>
import PersonaForm from "@/components/cliente/persona/PersonaForm";
import PersonaAPI from "@/api/persona";


export default {
  name: "PersonaUpdate",
  components: {
    PersonaForm,
  },
  props: {
    persona: {
        type: Object,
        required: true
    }
  },

  data() {
    return {
      provincias: [],
    };
  },

  methods: {

    async actualizarPersona(persona) {
      try {
        const response = await PersonaAPI.update(persona.id, persona);
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "success",
          text: "Actualizado",
          icon: "mdi-information",
          timeout: 3000,
        });
      } catch (error) {
        this.$updateBus.$emit("showSnackBar", {
          show: true,
          button: false,
          color: "info",
          text: "Error de red al actualiza",
          icon: "mdi-information",
          timeout: 3000,
        });
        // Maneja errores de red u otros errores inesperados
        console.error("Error de red al actualizar la persona:", error);
      }
    },
    async fetchProvincias() {
      try {
        const provincias = await this.axios.get("/soporte/provincias");

        this.provincias = provincias.data;
      } catch (error) {}
    },
  },
  mounted() {
    this.fetchProvincias();
  },
};
</script>

<style lang="scss" scoped></style>
