<template>
    <div>
        <v-select v-model="entity.condicionIva" :items="condicionesIva" value="nombre" outlined item-text="nombre"
            item-value="id" label="Condición de iva" name="condición iva" v-validate="'required'"
            :error-messages="errors.collect('condicionIva')" data-vv-name="condicionIva"></v-select>
    </div>
</template>

<script>
export default {
    name: 'CondicionIvaVSelect',
    $_veeValidate: {
    validator: "new",
  },
    props: {
        entity: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            condicionesIva: null,

        }
    },
    methods: {
        async fetchCondicionIva() {
            try {
                const response = await this.axios.get(`/comprobantes/condicionIva`);

                this.condicionesIva = response.data;
            } catch (error) { }
        },
    },
    created() {
        this.fetchCondicionIva();
    },
}
</script>

<style lang="scss" scoped></style>