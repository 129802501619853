<template>
  <v-card flat>
    <v-container>
      <v-card-text>
         <PersonaRegister @process-persona="validateForm"></PersonaRegister>
 

        <!-- <date-picker-date-of-birth
          @process-date-birthday="setDateOfBirth"
        ></date-picker-date-of-birth> -->

      </v-card-text>
     
    </v-container>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import DatePickerDateOfBirth from "@/util/DatePickerDateOfBirth.vue";
import PersonaRegister from "@/views/usuarios/PersonaRegister.vue";


export default {
  name: "ClienteForm",
  components: {
    DatePickerDateOfBirth,
    PersonaRegister

  },
  $_veeValidate: {
    validator: "new",
  },
  data() {
    return {
      loading: false,

      persona: null

    };
  },

  props: {
    cliente: {
      type: Object,
      required: true,
    },
    provincias: {
      type: Array,
      required: true,
    },
    textButton: {
      type: String,
      required: false,
      default: "Registrar",
    },

    isUpdate: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    setDateOfBirth(date) {
      this.cliente.fechaNacimiento = date;
    },
        setPersona(persona){
      this.persona = persona
    },
   
    validateForm(persona) {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {

          this.cliente.persona = persona
          this.$emit("processCliente", this.cliente);
        } else {
          this.$validator.validate();
        }
      });
    },
    
  },
  

  
};
</script>

<style lang="scss" scoped></style>
